.slate-editor blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}
.slate-editor table, .slate-editor th, .slate-editor td {
    border: 1px solid black;
}
table{
    border-collapse: collapse;
}
.slate-editor button {
    background-color: white;
    border: none;

}
.slate-editor  .btnActive {
    outline: 1px solid black;
}
.slate-editor .editor-wrapper {
    border-radius: 10px;
    background: #ffffff;
    box-shadow: -8px 8px 13px #ededed, 8px -8px 13px #ffffff;
    min-height: 400px;
    min-width: 100%;
    width: fit-content;
    height: fit-content;
    padding: 12px 10px;
}
.slate-editor  table {
    width: 100%;
}
.slate-editor td {
    height: 50px;
    padding: 0 5px;
}
.slate-editor .popup-wrapper {
    display: inline;
    position: relative;
}
.slate-editor .popup {
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: fit-content;
    z-index: 1;
}

.slate-editor code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.slate-editor [data-slate-node="element"] {
    margin: 10px 0;
}
.slate-editor .embed {
    width: fit-content;
    position: relative;
    margin-right: 20px;
}
    .slate-editor .embed img, .embed iframe {
        width: 100%;
        height: 100%;
    }
    .slate-editor .embed button {
        position: absolute;
        bottom: -6px;
        right: 0;
    }

.slate-editor .popup .image-text-input {
    min-width: 200px;
}
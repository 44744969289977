

.quickPollsContainer {
    list-style-type: none;
    padding: 0px;
    color: #093E52;
}

.quickActivePollCard {
    margin: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    min-width: 400px;
    background-color: white;
    height: auto;
}

    .quickActivePollCard:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

.limitPollPresenter {
    padding: 0px;
    margin: 2px 5px 5px -15px;
}

.limitPollAdmin {
    padding: 0px;
    
}


.quickPollCard {
    margin: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    min-width: 420px;
    background: #fdfdfd;
}

    .quickPollCard:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        background: #ffffff;
    }

    .quickPollCard ul {
        list-style-type: none;
    }

    .expanded {
        min-width: 800px;
        min-height: 600px;
    }

.padBox {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px;
    border: 20px;
}

.quickPollCardTitle {
    background-color: #009ca6;
    color: white;
    border-bottom:  2px solid #f0f0ff;
    padding:  0px 10px 0px 10px;
    font-size: 1rem;
    margin: -5px -5px 0 -5px}

.quickPollButtons {
    margin-left: 0px;
    margin-right: 0px;
    padding: 5px 0px 5px 0px;
    min-width: 380px;
}
.quickPollPauseButton {
    border: 1px solid grey;
    padding-left: 25px;
    padding-right: 25px;
    color: orange
}

.quickPollPlayButton {
    border: 1px solid #e0e0e0;
    padding-left: 25px;
    padding-right: 25px;
    color: #27ae60;
    border-radius: 3px;
}

.quickPollPublishButton {
    border: 1px solid #e0e0e0;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 5px;
    color: #4E94F1;
    border-radius: 3px;
}

.quickPollResetButton {
    border: 1px solid #e0e0e0;
    color: #c0392b;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    float: right;
    border-radius: 3px;
}

    .quickPollResetButton:disabled {
        color: grey;
    }

.quickPollDeleteButton {
    border: 1px solid #e0e0e0;
    color: #c0392b;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    float: right;
    border-radius: 3px;
}

    .quickPollDeleteButton:disabled {
        color: grey;
    }

.compactTable table,th, td {
    padding: 10px 0px 10px 10px;
    line-height: 1;
    margin: 10px;
    margin-left: 1px;
    border-collapse: separate;
    border: 1px solid lightgrey;
    border-spacing: 0px;
    text-align: center;
    min-width: 40px;
    font-size: .9rem;
}

.bar {
    width: 50%;
}

.quickPollCardChart
{
    position: absolute;
    bottom: 00px;
    right:40px;
    float: right;
    width: 65%;
    height: 95%;
}

.quickPollCardChartWide {
    position: absolute;
    /*    float: right;*/
    width: 90%;
    min-height: 500px;
    height: 95%;
    
}

.quickPollCardResults {
    min-height: 170px;
}

.quickPollCardGraphsMinWidth {
    min-width: 295px;
}

.quickPollCardGraphsPresenterMinWidth {
    margin-left: 40px;
    min-width: 295px;
    margin-right: -20px;
}

.pollTrendToggle {
    background: white;
    border-color: lightgrey;
    vertical-align: central;
    margin-top: 15px;
    height: 125px;
    width: 25px;
    float: right;
    font-size: .8em;
    color: grey;
    word-break: break-all;
    overflow:auto;
    white-space:normal;

}

.pollTrendToggle:hover {
    cursor: pointer;
}

.QPresText {
    padding: 5px 10px 1px 10px;
    margin-left: 10px auto;
    font-weight: 500;
    vertical-align: middle;
    min-height: 60px;
    color: #093E52 !important;
    font-size: 1.1rem;
}

    .QPresText.new {
        background: #f0f0f0;
    }

    .QPresText.open {
        /*background: rgba(15,200,1,.2);*/
        background: #ffffff;
        border-bottom: 5px solid #27ae60;
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .QPresText.closed {
        /*background: rgba(15,200,1,.2);*/
        background: #ffffff;
        border-bottom: 5px solid #c0392b;
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .QPresText.published {
        /*background: rgba(15,200,1,.2);*/
        background: #ffffff;
        border-bottom: 5px solid #F1614E;
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

.disablePollButton {
    background-color: grey;
}



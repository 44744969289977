
.slate-editor .color-options {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    align-items: center;
    gap: 5px;
}
.slate-editor .clicked {
    border: 1px solid lightgray;
    border-bottom: none;
}
.slate-editor .option, .hexPreview {
    width: 16px;
    height: 16px;
    background-color: #000000;
}
.slate-editor .color-picker form {
    display: flex;
    align-items: center;
    column-gap: 5px;
    width: 100%;
}
.slate-editor .color-picker input {
    width: 65%;
    height: 1.3em;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 5px
}
.slate-editor .color-picker button {
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.slate-editor .color-picker input:focus {
    outline: none;
}

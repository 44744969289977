/* Provide sufficient contrast against white background */
@import url("https://use.typekit.net/vwt7nvx.css");

body {
    font-family: museo-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1em;
    color: #093E52;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.deleteOptions {
    font-size: 1rem;
    padding-left: 10px;
    padding-top: 10px;
    width: 100%;
}


.cloud {
    background-color: #EBF3F480;
    border-radius: 5px;
    padding: 15px;
}

footer {
    margin-top: 15px;
    padding-top: 10px;
    /*border-top: 1px solid #e0e0e0;*/
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #ffffff;
}

    footer .row {
        margin-left: 15px;
        margin-right: 15px;
    }

.fillheight {
    background-color: white;
    border: 1px solid #eee;
}


.main {
    flex: 1;
    position: relative;
    min-height: 100vh;
    background-color: #fafafa;
}

.appParent {
    position: relative;
    display: flex;
    flex-direction: column;
}

.A4Reports {
    width: 21cm;
    padding-left: 1cm;
    padding-right: 1cm;
    padding-top: 1cm;
}

.addressReport {
    display: inline-block;
    text-align: right;
    margin-right: 0;
    margin-left: 65%;
    height: 100px;
}

.reportMeetingName {
    font-size: 2rem;
}

.reportMeetingTitle {
    font-size: 2rem;
    align-content: center;
    text-align: center;
    font-weight: 500;
    padding-bottom: 10px;
}


.reportMeetingDates {
    font-size: 1.5rem;
}

.logoReport {
    display: inline-flex;
    position: absolute;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 70%;
}


.headpad {
    margin-bottom: 5px;
    /*position: sticky;
    top: 0;
    z-index: 9999;*/
}

@media (min-width: 576px) {
    .appParent {
        flex-direction: row;
    }

    .headpad {
        height: 52px;
        color: #fff;
        background: #009CA6;
    }
}


.headpad-reset {
    height: 52px;
    background-color: #3b8896;
    background-image: linear-gradient(90deg, #093e52, #3b8896);
}

.reset-boxs {
    background-color: #EBF3F4;
    margin: 0 20px;
    padding: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 75vh;
}

.reset-page {
    width: 100%;
}

/*-----------------------Sidebar  --------------------*/
.adminTitle {
    color: #009CA6;
}


.sidebar {
    /*background-image: linear-gradient(220deg,#009ca6, #009ca6);*/
    /*background-color: #298ea0;*/
    border-right: 1px solid #eee;
}

    .sidebar .top-row {
        justify-content: center;
        height: 52px;
    }


    .sidebar .navbar-brand {
        /*        width: 100%;*/
        height: 100%;
        font-size: 1rem;
        margin: auto;
    }

        .sidebar .navbar-brand img {
            max-width: 80px;
        }

    .sidebar .oi {
        width: 2rem;
        font-size: 1rem;
        font-weight: 300;
        vertical-align: text-top;
        top: -2px;
    }

    .sidebar .nav-item {
        font-size: .9rem;
        padding-bottom: 0.0rem;
        font-weight: 300;
    }

        /* Causing a gap between menu and logout */
        /*            .sidebar .nav-item:first-of-type {
                padding-top: 1rem;
            }

            .sidebar .nav-item:last-of-type {
                padding-bottom: 1rem;
            }*/

        .sidebar .nav-item a {
            color: #575757;
            font-weight: 500;
            border-radius: 4px;
            align-items: center;
            border-bottom: 1px solid;
            border-color: rgba(136,136,136,0.1);
        }


            .sidebar .nav-item a.active {
                background-color: rgba(136,136,136,0.1);
/*                color: white;*/
            }

            .sidebar .nav-item a:hover {
                background-color: rgba(136,136,136,0.1);
                /*                color: white;*/
            }

    .sidebar .bottom-row {
        display: none;
    }

@media (max-width: 576px) {
    .sidebar .nav-item a {
        height: 3rem;
        display: flex;
        line-height: 3rem;
    }

        .sidebar .nav-item a div {
            padding-right: 5px;
        }
}

@media (min-width: 576px) {
    header.sideBar {
        position: relative;
        height: 100vh;
    }

    .sidebar .bottom-row {
        display: block;
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
    }

        .sidebar .bottom-row img {
            max-width: 75px;
            height: auto;
        }

    .sidebar .nav-item a div {
        align-items: center;
        text-align: center;
    }
}



.navbar-toggler {
    background-color: rgba(255, 255, 255, 0.1);
}


@media (min-width: 576px) {

    .sidebar {
        /*width: 200px;*/
        height: 100vh;
        position: sticky;
        top: 0;
    }

    .navbar-toggler {
        display: none;
    }

    .sidebar .collapse {
        /* Never collapse the sidebar for wide screens */
        display: block;
    }
}

@media (min-width: 576px) {
    .nav-link .icon {
        font-size: 1rem;
    }

    .nav-link.highlightlink {
        border: 1px solid rgba(237,28,36,0.3);
        background: rgba(237,28,36,0.9);
    }
}

.white_text {
    color: white !important;
    text-align: center;
}

.nav-link.active {
    background-color: rgba(136,136,136,0.1);
}

/*-------------End Sidebar -------------------------------*/
/* ---------- Generic Styling ---------------*/
input[type="text"], input[type="email"], input[type="password"] {
    /*background-color: #EBF3F4;
    border: none;*/
}

.btn-primary {
    /*border-radius: 25px;*/
    background-color: #009CA6;
    border-color: #009CA6;
    color: #fff;
}

    .btn-primary:hover,
    .btn-primary:focus {
        color: white;
        background-color: #093E52;
        border-color: #093E52;
    }

.btn-dark, .btn-danger, .btn-success {
    /*border-radius: 25px;*/
}

/* These are btn-primary colours, there's no point making them the same*/
/*.btn-success {
    background-color: #009CA6;
    border-color: #009CA6;
}*/

.form-check-input:checked {
    background-color: #009CA6;
    border-color: #009CA6;
}

.bg-brand-primary {
    background-color: #009CA6;
}

.bg-brand-secondary {
    background-color: #7B57CB;
}

.bg-brand-tertiary {
    background-color: #D62598;
}

/*comes with warning, no text or interface, gfx only */
.bg-brand-quaternary {
    background-color: #FF8A3D;
}

.bg-brand-charcoal {
    background-color: #262626;
}

.bg-brand-grey {
    background-color: #DDDDDD;
}

.bg-brand-lightgrey{
    background-color: #F2F2F2;
}

.bg-highlight {
    background-color: #dbe1fc; 
}

/* ---------- End Generic Styling ---------------*/
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin-bottom: 30px;
}

    .embed-container iframe, .embed-container object, .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

.askaquestionbox {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: end;
}

    .askaquestionbox .askaquestionbuffer {
        flex: 1 0 auto;
    }

    .askaquestionbox .askaquestion {
        flex-shrink: 0;
        text-align: right;
        position: sticky;
        padding-right: 25px;
        padding-bottom: 25px;
        right: 0;
        bottom: 0;
    }

        .askaquestionbox .askaquestion button {
            border-radius: 50%;
            height: 52px;
            width: 52px;
            background-color: #093e52;
        }

.errorMsg {
    color: #cc0000;
    margin-bottom: 5px;
}
/* css user friendly message  start*/
/*.message {
    width: 100%;
    margin: 0 auto;
    padding: 19px;
    background-color: #FFFFFF;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

.error {
    background-color: rgba(217, 80, 80, 0.2);
    border-top-right-radius: 31px;
    border: 1px solid #CC3333;
    border-right-width: 19px;
    border-left-width: 19px;
}

    .error strong {
        color: #FF3333;
    }

.warning {
    border-bottom-right-radius: 31px;
    background-color: rgba(240, 173, 78, 0.2);
    border: 1px solid #FF9933;
    border-right-width: 19px;
    border-left-width: 19px;
}

    .warning strong {
        color: #FF9900;
    }


.success {
    border-radius: 31px;
    background-color: rgba(43, 84, 44, 0.2);
    border: 1px solid #2b542c;
    border-right-width: 19px;
    border-left-width: 19px;
}

    .success strong {
        color: #2b542c;
    }

.bottomright {
    position: absolute;
    margin-top: 10px;
    bottom: -50px;
    right: 15px;
}*/

/* css user friendly message end */
.boldMsg {
    color: #222222;
    font-weight: 600;
}

.sucessMsg {
    color: #6B8E23;
    margin-bottom: 10px;
}

ul {
    margin: 0;
}

.dropzone {
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    margin-bottom: 20px;
    width: 100%;
    min-height: 120px;
}

.WelMsg label {
    width: 100%;
}

input[type="text"] {
    background: #FAFAFA;
    border-color: #F3F3F3;
}

.WelMsg input[type="text"] {
    width: 100%;
    padding: 8px;
}


/*--------Tabs-------*/
.react-tabs__tab {
    color: black;
    background-color: #eee;
    border-radius: 0;
    border-bottom: 1px solid #eee;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border: 1px solid #fff;
}

.react-tabs > ul > li {
    color: #575757;
}

.react-tabs__tab--selected {
    background-color: #009CA6 !important;
    top: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: #fff !important;
}

.react-tabs__tab-list {
    border: none;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    margin-top: 5px;
}

.react-tabs__tab--disabled {
    display: none;
}
/*--------End Tabs-------*/
/* Login menu dropdown */
#Titledropdown {
    max-width: 300px;
}


/*-------- Resolution Control Page  -------*/
.resolutionLeftBar {
    background-color: #093E52;
    width: 100%;
    height: 100%;
}

.resolutionButton {
    background-color: #EBF3F4;
    margin-bottom: 15px;
    padding: 10px;
    cursor: pointer;
}

    .resolutionButton.active {
        border: 5px solid #000000;
    }

    .resolutionButton.resPending {
        border-bottom: 5px solid #C3C3C3;
    }

    .resolutionButton.resOpen {
        background-color: #8CCFB7;
        border-bottom: 5px solid #8CCFB7;
    }

    .resolutionButton.resClosed {
        border-bottom: 5px solid #B62B60;
    }

    .resolutionButton.resPublished {
        border-bottom: 5px solid #F1614E;
    }

    .resolutionButton.resDisplay {
        border-bottom: 5px solid #DFE28B;
    }



.ResSelectList.MultiSelectBox input {
    width: 30px;
    height: 30px;
}

.ResSelectList.MultiSelectBox.pvHighlight input {
    outline: 2px solid red;
}

.ResSelectList.MultiSelectBox label {
    position: absolute;
    left: -9999px;
}

.ResolutionListButtonHolder {
    margin-top: 3px;
    margin-bottom: 5px;
}



/*-------- End Resolution Control Page -------*/
/*-------- START close resolution vote result Page -------*/
.resultGraph {
    height: 170px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.resultBarParent {
    height: 150px;
    text-align: center;
}

    .resultBarParent div {
        text-align: center;
    }

.resultBar {
    background: #F1614E; /* fallback for old browsers */
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.btnDisplay {
    background: #f1c40f;
    color: #ffffff;
    transition: opacity 0.5s ease;
    min-width: 80px;
}


.voteControlButton {
    width: 0px;
    overflow: hidden;
}

    .voteControlButton.active {
        width: auto;
        margin-left: 5px;
        margin-right: 5px;
        -webkit-animation: appear-from-center 0.5s ease-in-out;
        animation: appear-from-center 0.5s ease-in-out;
    }

.buttonsActive {
    -webkit-animation: appear-from-center 0.5s ease-in-out;
    animation: appear-from-center 0.5s ease-in-out;
}

.buttonsInActive {
    -webkit-animation: disappear-to-center 0.5s ease-in-out;
    animation: disappear-to-center 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

@-webkit-keyframes appear-from-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes appear-from-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes disappear-to-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@keyframes disappear-to-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}





.btnOpen {
    background-color: #27ae60;
    color: #ffffff;
    transition: opacity 0.5s ease;
    min-width: 80px;
}

.btn.btnOpen:hover {
    color: #ffffff;
    background-color: #27ae60;
    opacity: 0.7;
    transition: opacity 0.5s ease;
}

.btnClose {
    background-color: #c0392b;
    color: #ffffff;
    transition: opacity 0.5s ease;
    min-width: 80px;
}

.btn.btnClose:hover {
    color: #fff;
    background-color: #c0392b;
    opacity: 0.7;
    transition: opacity 0.5s ease;
}

.btnPublish {
    background-color: #F1614E;
    color: #ffffff;
    transition: opacity 0.5s ease;
    min-width: 80px;
}

.btn.btnPublish:hover {
    color: #ffffff;
    background-color: #F1614E;
    opacity: 0.7;
    transition: opacity 0.5s ease;
}

.btnReset {
    background-color: #4E94F1;
    color: #ffffff;
    transition: opacity 0.5s ease;
    min-width: 80px;
    width: 50%;
    float: right;
}

.btn.btnReset:hover {
    color: #ffffff;
    background-color: #4E94F1;
    opacity: 0.7;
    transition: opacity 0.5s ease;
}

.btn.disabled, .btn:disabled {
    opacity: 1;
    background-color: #e0e0e0;
    border-color: #e0e0e0;
}

.btnDisplay, .btnOpen, .btnClose, .btnPublish {
    width: 100%;
}


.ResPanel {
    background: #EBF3F4;
    padding: 20px;
}

.EditText {
    background: #ffffff;
    padding: 10px;
}

    .EditText button {
        float: right;
    }

.dashHeading {
    background: #D2E1E3;
    padding: 1px;
    margin-top: 10px;
}

    .dashHeading .col, .dashRow .row {
        text-align: center;
    }

.dashRow {
    background: #ffffff;
    padding: 1px;
    margin-top: 10px;
}

.attendeeRow {
    background: #ffffff;
    padding: 5px;
}

.cloudGrid {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 0px 0 0 0;
    flex-basis: 0;
    width: 100%;
    flex-grow: 1;
}

    .cloudGrid table {
        margin-bottom: 0;
    }

    .cloudGrid td {
        /*border: 0px;*/
    }

    .cloudGrid tr {
        border: 0px;
        margin: 0px;
        font-size: .8em;
    }

.PvotingSummary .cloudGrid .col {
    padding-left: 0;
    padding-right: 0;
}


.cloudres {
    background-color: #ffffff;
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: flex-end;
    flex-basis: 0;
    flex-grow: 1;
}

.resultGraph .resultBarParent:first-child .resultBar {
    background: #27ae60;
}

.resultGraph .resultBarParent:nth-child(2) .resultBar {
    background: #f1614e;
}

.resultGraph .resultBarParent:nth-child(3) .resultBar {
    background: #3498db;
}
/*.dashRow .row .col:nth-child(1) {
        background: #D2E1E3;
    }*/

.pvVoteSelectBox input {
    width: 30px;
    height: 30px;
}
/*-------- END close resolution vote result Page -------*/
/*--------------Setup page--------------*/
.quill {
    border: 1px solid #F3F3F3 !important;
    background: #FAFAFA;
    border-radius: 0.25rem;
}

.ql-toolbar.ql-snow {
    border: 0px solid #F3F3F3 !important;
    background: #F3F3F3;
}

.ql-container.ql-snow {
    border: 0px !important;
    background: #FAFAFA;
}

.files-dropzone-list {
    background: #EBF3F4;
    border: 1px solid #EBF3F4 !important;
    width: 100%;
    height: auto !important;
    text-align: center;
    font-size: 0.8rem;
}

.uploadIcon {
    color: #fff;
}

.logoPrev {
    /*border: 2px solid #EBF3F4;
    padding: 10px;*/
    margin-bottom: 12px;
}
/*------------Meeting Configuration-------------*/
.table-hover tbody tr:hover {
    background-color: #EBF3F4;
}

.table-hover tbody tr, .table th, .table td {
    vertical-align: middle;
}

th.sortable {
    color: #212529;
}

.asrt-table-foot {
    margin-bottom: 20px;
}

.btn-dark {
    background-color: #093E52;
    border-color: #093E52;
}

.btn-danger {
    background-color: #ff6b6b;
    border-color: #ff6b6b;
}

.btn-toolbar button {
    min-width: 60px;
}
/*------------Documents-------------*/
.dropBox {
    width: 100% !important;
}

.draggable li {
    /*border: 1px solid #EBF3F4 !important;
    box-shadow: none !important;*/
}

.editBtnD {
    margin-left: 7px;
}

.editBtnE {
    margin-left: 7px;
}

.DocumentList {
    border-left: 1px solid #f4f4f4;
}

    .DocumentList ul {
        padding-left: 0;
    }

.resSelectOption {
    /*    outline: red solid 3px;*/
}

.proxyOption {
    display: block;
}

.makebtnRadio {
    display: flex;
    flex-wrap: nowrap;
}

.btnRadio {
    display: inline-block;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 3px grey;
    border: 1px solid white;
    height: 13px;
    width: 13px;
    margin-right: 5px;
    margin-top: 3px;
    padding: 1px;
}

.resSelectOption .btnRadio {
    background-color: blue;
}

.btnRadioText {
    display: inline-block;
    padding: 1px;
}


/* users page*/
table.table-fit {
    width: auto !important;
    table-layout: auto !important;
    border-collapse: collapse;
}

    table.table-fit thead th, table.table-fit tfoot th {
        width: auto !important;
    }

    table.table-fit tbody td, table.table-fit tfoot td {
        width: auto !important;
    }
/* End users page*/

/* styles.css */

.react-switch {
    vertical-align: middle;
}


.stable {
    width: 75%;
}

.id {
    width: 1%;
    padding: 20px;
}

.resolutionIconContainer {
    position: relative;
    display: flex;
    border: 0px solid #e0e0e0;
    /* right: -8px; */
    /* bottom: 18px; */
    font-size: 1rem;
    /* float: right; */
    margin-top: 5px;
    justify-content: flex-end;
}

caption {
    color: red;
    caption-side: top;
}

.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.resolutionIconBox {
    display: flex;
    border: 2px solid #093e52;
    border-radius: 20%;
    background-color: white;
    padding: 5px;
    margin: 1px;
    text-align: center;
    font-size: .7rem;
}

.resolutionText {
    float: left;
}

.files-list-item-content-item, .files-list-item-content-item span {
    cursor: pointer;
}

.attendeeSummaryBoxPres {
    background-color: #EBF3F4;
    padding: 3px !important;
    font-size: .8rem;
    border-radius: 4px;
    /*border: 2px solid #EBF3F4;*/
}

.attendeeSummaryBox {
    background-color: #EBF3F4;
    padding: 21px;
    border: 3px solid #ffffff !important;
}

.summaryBoxText {
    font-size: 0.9rem;
    padding-top: 5px;
}

.hMargin {
    margin-top: 10px;
}

.Reports {
    padding: 20px;
}

.VotingSummary {
    padding: 0px;
}

.presenterWrapper {
    padding: 5px 0px 0px 10px;
    border: 0px;
}

    .presenterWrapper .col {
        padding: 0px;
        border: 0px;
        margin: 0px;
    }

.awaitfinalised {
    text-align: center;
    margin-bottom: 25px;
}

.pvsummary-heading {
    display: block;
    margin-bottom: 10px;
}

.PvotingSummary {
    display: flex;
    padding: 10px 0 0 0;
    top: 0px;
}

.resolutionBox {
    padding: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    border: 1px solid #e0e0e0;
    background: #fcfcfc;
    border-radius: 4px;
}

.MultiContestBox {
    border: 1px solid #e0e0e0;
    background: #fcfcfc;
    padding: 10px;
    border-radius: 4px;
}

.ResolutionBox {
    border: 1px solid #e0e0e0;
    background: #fcfcfc;
    height: calc(100% - 10px);
    padding: 10px;
    border-radius: 4px;
}

    .resolutionBox.active {
        border: 3px solid #484848;
    }

    .resolutionBox:hover {
        box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
        -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
        -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
        -o-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
        -ms-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    }

.continuousOpenContests {
    padding: 5px 5px 10px 5px;
    background: white;
    margin-bottom: 10px;
    margin-left: 5px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
}



.ls {
    margin-top: 14px;
}

.smallTable {
    font-size: small
}

.col .dashNumber {
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0px 10px 0px 0px;
    border-right: 5px solid white;
}

.col .attendeeNumber {
    font-weight: 600;
    font-size: 1.2rem;
    background: #D2E1E3;
    text-align: center;
    border-right: 10px solid #ffffff;
}

.col .attendeeText {
    background: #EBF3F4;
    border-left: 10px solid #ffffff;
}

.row .dashRow {
    padding-bottom: 10px;
}

.circleprogress {
    display: flex;
    float: right;
    max-width: 85px;
    max-height: 85px;
    position: relative;
    right: 0px;
    top: 0px;
    z-index: 5;
    border: 5px black;
    margin-left: 5px;
    margin-top: 5px;
}

.barChart {
    width: 100%;
    margin-top: 1px;
    background: white;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 5px;
    margin-bottom: 5px;
    min-height: 125px;
}

.barChartRef {
    min-height: 140px;
}

.spacer {
    min-height: 20px;
    height: 50px;
    background: #8CCFB7;
    display: inline-block;
}

.progressResolutions {
    position: relative;
    left: -0px;
    padding: 0px 0px 20px 0px;
    width: 75%;
}

.progressResInner {
    margin-left: 10px !important;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
}

.resolutionSpacing {
    padding: 1px 0;
    border-bottom: 1px solid #f4f4f4;
    background: #ffffff;
}

.loginSpace {
    margin-bottom: 10px;
}

.welcomeHeading {
    margin-top: 10px;
}

.adminProxyList div {
    margin-bottom: 6px;
}

.adminProxyList .files-list-item-content-item-1 button {
    width: 100%;
}

.files-list .search {
    margin-bottom: 10px;
}

.proxyButtons {
    margin-bottom: 10px;
}


.emailInput {
    width: 100%;
}

table th, table td {
    padding: 5px !important;
}

.btnPadding {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
}

/*Bootstrap Table*/
/*.table .thead-light th {
    color: #ffffff;
    background-color: #777777;
    border-color: #777777;
}*/
/*Contest Page*/
#contestPage .btn-toolbar .btn {
    /*margin-right: 10px;*/
}
/*Modals*/
.modal-backdrop {
    background-color: rgba(0,0,0,0.5);
}

.modal-content {
    border: 0 solid rgba(0,0,0,.2);
}

.modalContestContent {
    height: 75vh;
    overflow: hidden;
    position: relative;
}

.modalContestBody {
    height: calc(100% - 60px);
    width: 100%;
    position: absolute;
    padding-right: 15px;
    top: 0;
    overflow-y: auto;
}

.modalUpdateFooter {
    height: 60px;
    width: 100%;
    padding: 10px;
    position: absolute;
    bottom: 0;
    border-top: 1px solid grey;
    /*    text-align: right;*/
}

.modalUpdateFooterSubmit {
    text-align: right;
}

.modal-title {
    width: 100%
}

.modal-80w {
    width: 80vw;
    max-width: 1400px !important;
}

.modal-65w {
    min-width: 65vw;
}


.xVoteCheck input[type=checkbox] {
    width: 25px;
    height: 25px;
    margin-top: 0;
    margin-left: 5px;
    margin-right: 5px;
}

.graphPadding {
    padding: 0 20px 0 20px;
}

.attendanceWrapper {
    /*background-color: white;*/
    /*    border-bottom: 3px solid white;
    border-top: 3px solid white;*/
    margin: 0px;
}


.whitecolumn {
    min-width: 10px;
    padding: 0px;
    border: 0px;
}

.whitespacer {
    min-height: 5px;
}


.carouselPadding {
    padding: 0px 20px 0px 20px;
    margin-bottom: 10px;
    background-color: white;
}

.chatResponse {
    background: #f3f3f3;
    padding: 0 0 0 5px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #999999;
}

.subStats {
    font-size: 1em;
    padding: 0 10px 2px 10px;
}

.sessionWarning {
    background-color: red;
    color: white;
    border: 2px double red;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    text-align: center;
}

/*** Used in RReports.js for the Post Meeting Tables  ****/

.postReportsTable {
    font-size: 1em;
}

    .postReportsTable td {
        text-align: center;
    }

        .postReportsTable td:nth-child(1) {
            text-align: left;
        }


    .postReportsTable th {
        text-align: center;
        min-width: 75px;
        background-color: #e7f3f5;
    }

        .postReportsTable th:nth-child(1) {
            text-align: left;
        }

    .postReportsTable tr:nth-child(1) {
        background-color: #5a92ce
    }

    .postReportsTable.candidateResults {
        width: 100%;
        margin-bottom: 15px;
    }

    .postReportsTable tbody:not(:first-child):before {
        content: '';
        display: table-row;
        height: 10px;
    }


.testWarning {
    display: inline-block;
    color: red;
    background-color: white;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
    border-radius: 2px;
    white-space: nowrap;
    height: 25px;
}

.SigR {
    margin: 5px;
    margin-right: 15px;
}

    .SigR.Connected {
        color: limegreen;
        animation: blinker 1s linear infinite;
    }

        .SigR.Connected.Groups {
            color: limegreen;
            animation: none;
        }

    .SigR.Reconnecting {
        color: orange;
        animation: blinker 1s linear infinite;
    }

    .SigR.Disconnecting {
        color: #f5710f;
        animation: blinker 1s linear infinite;
    }

    .SigR.Disconnected {
        color: red;
    }

    .SigR.Connecting {
        color: dodgerblue;
        animation: blinker 1s linear infinite;
    }

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.meetingContainer {
    background: rgba(255,255,255,0.3);
    padding: 20px;
}

.titlePad {
    padding-bottom: 10px;
}

.buttonDanger {
    background: red;
    width: 100px;
    color: white;
}

    .buttonDanger:disabled {
        background: red;
        opacity: .3;
        width: 100px;
        color: white;
        border: solid 1px darkgrey;
    }

.buttonWarning {
    background: orange;
    width: 100px;
    color: white;
}

    .buttonWarning:disabled {
        background: orange;
        opacity: .3;
        width: 100px;
        color: white;
        border: solid 1px darkgrey;
    }

.buttonSafe {
    background: blue;
    width: 100px;
    color: white;
}

    .buttonSafe:disabled {
        background: blue;
        opacity: .3;
        width: 100px;
        color: white;
        border: solid 1px darkgrey;
    }

.draggable .table {
    margin-bottom: 0.3rem !important;
}

.dragIcon {
    margin: 0 15px 0 10px;
}

.CenterHeading {
    text-align: center;
}


ul.VoteOptions {
    padding-left: 1px;
    z-index: 99999 !important;
}

.VoteOptions {
    z-index: 99999 !important;
}

span.clearData {
    padding-right: 10px;
    color: #fff;
}

.deleteDataAll {
    background: #d63031;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: white;
    max-height: calc(100vh - 60px);
    overflow: hidden;
}

.attendeeCount {
    color: #fff;
    margin-right: 15px;
    font-size: 1em;
    text-align: right;
    border: 5px;
    padding: 5px;
}

.fileNameText input {
    width: 250px;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    border: 1px solid #000000;
}

.exportChat {
    margin-left: 45%;
    padding: 10px;
    align-content: center;
    background: #009ca6;
    color: white;
}

    .exportChat:hover {
        cursor: pointer;
    }

.mainArea {
    padding-left: 10px;
    padding-right: 10px;
}

.col.addPadding {
    padding: 0px 10px 0px 0px;
}

.premeetingTableContainer {
    max-height: 500px;
    overflow-y: auto;
}

#resListColumn {
    max-height: 70vh;
    overflow-y: auto;
}

/*-----Xvote and candidate setup-----*/

.candidatePhotoPreview {
    max-width: 100%;
    height: auto;
}

.candidateSetup .row {
    margin-bottom: 10px;
}

.candidateList {
    padding: 0.8em;
    margin: 0.5em 0em;
    list-style-type: none;
    border: 1px solid #EBF3F4;
    background: #EBF3F4;
    box-shadow: none;
    color: #333;
    border-radius: 5px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    z-index: 9999;
}

#openResDashboardb {
    padding: 0 10px 10px 10px;
}

.attendee-lists .table thead th {
    color: #fff;
    background-color: #009ca6;
    font-weight: normal;
}

.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 1px solid grey;
    border-radius: 50%;
    /*    margin-left: -15px;
    margin-right: -15px;*/ /*causes buttons to fall off the view*/
    margin-top: 115px;
    width: 30px;
    background-color: white;
    color: white !important;
    padding: 2px 4px 0px 2px;
    height: 30px;
    opacity: 1 !important;
}

.meetingPage {
    width: 100%;
    height: 100vh;
}

.meetingtable {
    background-color: #fff;
    margin-top: 100px;
    width: 500px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
    border-radius: 10px;
    /*    border: 1px solid #eee;*/
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
    -o-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    -ms-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
}

.meetingHeading {
    /*border-bottom: 5px solid #009CA6;*/
}
/*-----End Xvote and candidate setup-----*/
.xVoteSorted {
    color: forestgreen
}

.tableMemberType {
    margin-left: 15px !important;
    width: 500px;
}

.popupPad {
    margin-top: 15px;
}

#selectedProxy {
    background-color: #ccc;
}

.ChairProxyVoteAlert {
    padding: 3px;
    background-color: #ff6b6b;
    color: #fff;
    border: 1px solid #ff6b6b;
    border-radius: 3px;
    margin-bottom: 5px;
}

.pinkIcon {
    color: #D62598;
}

.greenText {
    color: #009CA6;
}

.nobullets {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dragHandle {
    cursor: grab;
}

.row-contest {
    display: flex;
    width: 100%;
}

.col-contest {
    flex: 1;
}

.documentElement {
}

.grid-container-contest {
    display: grid;
    grid-template-columns: 30px auto;
    grid-template-rows: min-content;
    grid-template-areas: "Status Title-Bar Title-Bar Time Close" "Status Message Message Message";
    font-size: .8em;
    background-color: white;
    padding: 4px;
    margin-bottom: 5px;
    border: 1px solid #eee;
}

.grid-container-contest-header {
    display: grid;
    grid-template-columns: 30px auto;
    grid-template-rows: 35px;
    grid-template-areas: "Status Title-Bar Title-Bar Time Close" "Status Message Message Message";
    font-size: .8em;
    background-color: #eee;
    padding: 4px;
    margin-bottom: 5px;
    border: 1px solid #eee;
    height: 60px;
}

.longColumn {
    width: 32%;
}

.shortColumn {
    width: 8%;
}

.shortColumn10 {
    width: 10%;
}

.grid-container-contest:hover {
    cursor: pointer;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
    -o-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    -ms-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
}

.sketch-picker {
    width: 100% !important;
}

.scrollQuickPoll {
    overflow: auto;
    white-space: nowrap;
}


/**** Constituencies section *****/
.constituencyCard {
    border: 1px solid #eee;
    padding: 3px;
    margin: 5px;
    background-color: white;
    color: none;
    cursor: pointer;
}

    .constituencyCard.selected {
        border: 1px solid #eee;
        padding: 3px;
        background-color: cadetblue;
        color: white;
        box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
        -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
        -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
        -o-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
        -ms-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    }

    .constituencyCard:hover {
        box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
        -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
        -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
        -o-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
        -ms-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    }

    .constituencyCard input:disabled {
        cursor: pointer;
    }

.constituencyNumber {
    display: inline-flex;
    max-width: 3rem;
    background-color: white;
    color: black;
    margin-right: 2px;
    width: 7%;
}

    .constituencyNumber:read-only {
        display: inline-flex;
        border: none;
        user-select: none;
        background-color: inherit;
        width: 8%;
    }

    .constituencyNumber:disabled {
        display: inline-flex;
        background-color: inherit;
        color: inherit;
        width: 8%;
    }

    .constituencyNumber input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;
    }


.constituencyCard input[type=number] {
    -moz-appearance: textfield;
}

.constituencyNumber input[type="number"]::-ms-clear {
    display: none;
}

.tooltip {
    /* visibility: hidden; */
    display: none;
    position: absolute;
    top: 100px;
    left: 0;
    background-color: #fff;
    padding: 10px;
    -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.3);
    opacity: 0;
    transition: opacity 0.5s ease;
}



.constituencyDescription {
    display: inline-flex;
    /*border: none;*/
    user-select: none;
    /* background-color: inherit;*/
    color: black;
    margin-right: 5px;
    overflow: hidden;
    white-space: normal;
    min-width: 50%;
}

.constituencyDescriptionReadOnly {
    display: inline-flex;
    border: none;
    color: inherit;
    user-select: none;
    background-color: inherit;
    white-space: normal;
    min-width: 50%;
}

    .constituencyDescriptionReadOnly:read-only {
        display: inline-flex;
        border: none;
        user-select: none;
        background-color: inherit;
        min-width: 50%;
    }

    .constituencyDescriptionReadOnly:hover + .tooltip {
        display: initial;
        text-overflow: initial;
        overflow: auto;
        transition: opacity 0.5s ease;
        opacity: 1;
    }


.userCount {
    width: 15%;
    display: inline-flex;
}

.constituencyButtons {
    display: inline-flex;
    right: 0px;
    float: right;
    margin-right: 0px;
    width: 24%;
}

.constituencyButton {
    display: inline-flex;
    margin-left: 1px;
    height: 1.5rem;
    padding-top: 0px;
}

.contestLock {
    display: inline-flex;
    text-align: center;
    min-width: 1.5rem;
    color: darkred;
}

.contestCard {
    border: 1px solid #eee;
    padding: 3px;
    margin: 5px;
}

.contestCardChecked {
    border: 1px solid #eee;
    background-color: cadetblue;
    padding: 3px;
    margin: 5px;
    color: white;
}

.crefsGood {
    margin: 5px;
    color: white;
}

.crefsBad {
    margin: 5px;
    color: red;
}

.errorMessage {
    color: red;
}

.green {
    color: green;
}

.constituencyContestList input[type=checkbox] {
    transform: scale(1.75);
    margin-right: 0.5em;
    cursor: pointer;
}

.constituencyContestList label {
    display: inline-block;
    cursor: pointer;
}
/* end constituencies */
.loaderholder {
    position: relative;
    height: 20px;
    width: 100px;
}

.inlineloader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #009CA6;
    border-bottom: 4px solid #009CA6;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    animation: spin 2s linear infinite;
    margin: 00px 00px 00px 00px;
    padding-left: 10px;
    z-index: 1;
}

.dropdownHolder {
    position: relative;
    height: 20px;
    width: 500px;
}

.chatloaderholder {
    position: relative;
    height: 100%;
    width: 100%;
}

.groupChatloaderholder {
    position: relative;
    height: 100%;
    width: 16px;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #009CA6;
    border-bottom: 4px solid #009CA6;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    z-index: 1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.registrationLogo {
    width: 100%;
    text-align: center;
    padding: 25px;
    padding-top: 15px;
}


.registrationSubTitle {
    text-align: center;
    color: #009CA6;
    font-size: 1.2rem;
}

.registrationText, .confirmationText, .registrationButton, .registrationButtonSmall, .registrationSearchBox {
    padding: 20px;
}

    .registrationText h1 {
        text-align: center;
        font-size: 2.5rem;
        color: #009CA6;
    }

    .registrationText p {
        text-align: center;
        font-size: 1.5rem;
        color: #009CA6;
    }

.registrationKey, .registrationValue {
    font-size: 2rem;
    color: #009CA6;
    width: 50%;
    display: inline-block;
}

.registrationKey {
    text-align: right;
}

.registrationValue {
    text-align: left;
}

.registrationButton button {
    padding: 25px;
}

.registrationSearchBox {
    border: 1px solid lightgrey;
}

    .registrationSearchBox table {
        width: 100%;
    }

        .registrationSearchBox table tr:not(:first-child) {
            border-radius: 12px;
        }

            .registrationSearchBox table tr:not(:first-child) td:first-child {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }

            .registrationSearchBox table tr:not(:first-child) td:last-child {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }

            .registrationSearchBox table tr:not(:first-child):hover, .registrationSearchBox table tr:not(:first-child):focus {
                /*            outline: 2px solid LightSkyBlue;*/
                box-shadow: 0 0 3px Blue;
                cursor: pointer;
            }

        .registrationSearchBox table tr.SelectedAttendee {
            background-color: green;
            cursor: pointer;
            color: white;
        }


        .registrationSearchBox table th, .registrationSearchBox table td {
            border: none;
        }

.registrationValidationMessage {
    color: red;
    padding: 20px;
}

    .registrationValidationMessage p {
        margin-bottom: 0;
    }

.QrReader {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: none;
    width: 100%;
    max-width: 250px;
}

@media screen {
    #printSection {
        display: none;
    }

    #printThis {
        display: none;
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    #printSection, #printSection * {
        visibility: visible;
        max-width: 100%;
    }

    #printSection {
        position: absolute;
        left: 0;
        top: 0;
    }

    #printThis .registrationLogo {
        max-width: 250px;
    }

        #printThis .registrationLogo img {
            width: 100%;
            height: auto;
        }
}

.showOfHands div {
    margin-bottom: 5px;
}

.showOfHands .btn-primary {
    border-radius: .25rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.tableHeading {
    background-color: #fcfcfc;
    color: #009CA6;
}

    .tableHeading.textfit {
        font-size: 0.85vw;
    }

.formPad {
    padding: 30px 10px 0 10px;
}

.leftStat {
    text-align: left;
    border-left: 1px solid #fff;
}

.statH {
    background-color: #d8e7e9;
    border-left: 2px solid #EBF3F4;
    border-right: 2px solid #EBF3F4;
    border-bottom: 2px solid #fff;
    border-top: 2px solid #EBF3F4;
    margin-bottom: 2px;
}

.SeenAndHeard {
    max-height: calc( 80vh - 50px);
    overflow-y: auto;
}

.seenandheardtext {
    width: 100%;
}

.pointer {
    cursor: pointer;
}

.deleteCheckbox input {
    position: inherit;
    margin-top: 5px;
    vertical-align: central;
    left: 40px;
    height: 20px;
    width: 40px;
    background: #ddd;
}

.alert-warning {
    color: black;
}


/*Lost bootstrap class fix*/
.float-right * {
    float: right;
}


/*----OpenChat----*/
.adminOpenChatParent {
    height: 80vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 50%;
}

    .adminOpenChatParent div:nth-child(1) {
        grid-column: 1;
        grid-row: 1 / 3;
    }

.leftColumn, .openChatParent {
    overflow-y: auto;
    height: 100%;
}


.openChatParent {
    /*border: white;*/
    background-color: rgba(250, 250, 250, 0.3);
    border-radius: 7px;
    position: relative;
    font-size: 0.85rem;
    padding: 0.2rem;
    /*max-width: 50vw;*/
}

.openChatPost {
    scroll-snap-align: end;
}

.openChatCommentButton {
    color: #0366D6;
    font-weight: normal;
    font-size: 0.8rem;
}

.postContent {
    border-radius: 0px 5px 5px 5px;
    border: 1px solid #a0d6fa;
    background-color: #cfeafc;
    color: #101010;
    overflow: hidden;
    text-overflow: ellipsis;
    /*    border: 1px solid #e0e0e0;
    background-color: #f4f4f4;*/
}

.postOwner .postContent {
    border: 1px solid #2512a3;
    background-color: #2056a1;
    color: white;
    /*border-radius: 3px;
    border: 1px solid #C3F5B8;
    background-color: #ECFFE8;*/
    /*    border: 1px solid #e0e0e0;
    background-color: #f4f4f4;*/
}

.postHidden .postContent {
    color: black;
    background-color: lightgray;
    border: 1px solid lightgray;
}

.postHidden .postHiddenBy {
    background-color: white;
}

.openChatReply.postContent {
    /*    border: 1px solid #a0d6fa;
    background-color: #cfeafc;*/
    color: black;
    border: 1px solid #e0e0e0;
    background-color: #f4f4f4;
}

.postContent .dropdown-menu {
    min-width: 6rem;
}

.postContent .readMoreButton {
    font-size: 0.7rem;
}

.openChatLikeDislike .btn:disabled {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    background-color: white;
    border-color: white
}

.likeCountDisplay {
    font-size: 0.7rem;
    min-width: 16px;
    text-align: center;
}

.openChatPost .dropdown-toggle::before {
    display: none;
}

.openChatModal .form-check-input:checked {
    background-color: #009CA6;
    border-color: #009CA6;
}

.openChatModal legend {
    font-size: 1rem;
}

.openChatContentHolder {
    position: relative;
}

.scrollToBottomButton.btn {
    border-radius: 50%;
    position: absolute;
    bottom: 110px;
    right: 10px;
    color: var(--main-theme-color);
    background-color: var(--main-client-color);
    width: 32px;
    height: 32px;
    text-align: center;
}

.scrollToBottomButton .svg-inline--fa {
    width: 14px;
    height: 14px;
}

.emojiPickerContainer {
    position: absolute;
    bottom: 100px;
    left: 0;
    background-color: white;
    z-index: 2;
    -webkit-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
}


.emojiPicker {
    background-color: #f1f1f1;
}

.emojiButton {
    flex-basis: 0px;
    flex-shrink: 1;
    min-width: 2.2rem;
    text-align: center;
    transition: all .2s ease-in-out;
    background: rgba(0,0,0,0);
}

    .emojiButton:hover {
        transform: scale(1.5);
    }

.emoji-icon {
    font-size: 1.4rem;
    cursor: pointer
}

.moderatorTabs .react-tabs__tab-panel--selected {
    flex-grow: 1;
}

/*----End OpenChat----*/
/*----Start User Admin ----*/

.permissionList .switchBox {
    background-color: #fcfcfc;
    border: 1px solid #e0e0e0;
    height: 100%;
    padding: 10px;
    border-radius: 3px
}

.permissionList .switchBoxHeading h5 {
    color: #9b59b6;
}

.permissionList .permissionHeading .permissionType {
    font-size: smaller;
    font-weight: bold;
}

.permissionHeading .readPermission {
    color: #2ecc71;
}

.permissionHeading .writePermission {
    color: #9b59b6;
}

.permissionHeading .grantPermission {
    color: #3498db;
}

.permissionHeading .revokePermission {
    color: #e74c3c;
}

.permissionHeading input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    accent-color: #009CA6;
}

.permissionAction .dropdown-toggle::after {
    display: none;
}

.permissionSection {
    height: 64vh;
}

    .permissionSection .card-body {
        overflow-y: auto;
    }

.useradmincard .card-header {
    background-color: #009CA6;
    border-color: #009CA6;
    color: #fff;
    cursor: default;
}

.inheritedBadge {
    background-color: #6f42c1
}

.clientBadge {
    background-color: #0d6efd
}

.userAdminModal .react-tabs__tab--selected {
    pointer-events: none
}

.userAdminModal .react-tabs__tab:not(.react-tabs__tab--selected) {
    display: none;
}

.featureList input[type="checkbox"] {
    width: 1.2rem;
    height: 1.2rem;
    accent-color: #009CA6;
}

.addPermissionModal .modal-dialog {
    height: 90vh;
    max-width: 90%;
}

.addPermissionModal .modal-content {
    height: 90vh;
}

/*----End User Admin ----*/

/*--------Survey--------*/

.form-div {
    margin-top: 2rem;
}

.form-text {
    margin-top: 0.5rem;
}

.card-div {
    margin-top: 1rem;
}

.button-margin {
    margin-top: 1rem;
}

.button-margin-2 {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.button-margin-right {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
}

.alert-margin {
    margin-top: 2rem;
}

.header-margin {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.dnd {
    cursor: move;
    transition: all 0.2s;
}

.dnd:hover {
    background-color: #e2e3e5 !important;
}

.response-lists .table thead th {
    color: #fff;
    background-color: #009ca6;
    font-weight: normal;
}

/*--------Survey End--------*/

/*----- ComfortMonitor ----*/   

.message-group .btn:focus{
    outline: 2px solid black;
}

.grab-icon {
    cursor: grab;
}

.smaller{
    font-size: 0.9em;
}


.smaller-still {
    font-size: 0.75em;
}

.dragging{
    outline: 2px solid lightblue;
}

.drag-over {
    outline: 2px solid lightgreen;
}

.tag-manager:focus-within {
    outline: 2px solid black;
}

.tag-manager :focus:not(button){
    outline: none;
}


.tag-form-control {
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.tag-lozenge::first-letter {
    text-transform: uppercase
}
.comfort-wrapper{
    height: calc(97vh - 50px);
}
.cm-message-notes-padding {
    margin-bottom: 30px;
}
.cm-message-notes {
    margin-top: -30px;
}

.autocomplete-parent {
    position: relative;
}

.autocomplete {
    position: absolute;
    top: 100%;
    left: 15px;
    background: white;
}
.autocomplete-list {
    list-style: none;
    padding-left: 0;    
}

    .autocomplete-list li {
        background-color: #e9e9e9;
        cursor: pointer;
        list-style-type: none;
        border-radius: 3px;
    }
        .autocomplete-list li button:hover, .autocomplete-list li button:focus {
            background-color: white;
            outline: 1px solid black;
        }
/*----- End Comfort Monitor ----*/
/* Chat css */

.react-tabs > ul > li {
    color: #093E52;
}

.chat-box {
    background-color: #fff; /*#EBF3F4*/ 
    margin: 0px 0px 20px 0px;
    padding: 5px 5px 5px 0px;
    border: 1px solid #eee;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    display: flex;
    flex-direction: column;
    height: 75vh;
    overflow-y: auto;
}
.presenter-left--box {
    background-color: #fff; /*#EBF3F4*/
    margin: 0px 0px 20px 0px;
    padding: 5px 5px 5px 0px;
    border: 1px solid #eee;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    display: flex;
    flex-direction: column;
    height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.grid-container-pres {
    display: grid;
    grid-template-columns: 0.3fr 2.5fr 0.3fr 0.3fr;
    grid-template-rows: 15px;
    grid-template-areas: "Status Title-Bar Title-Bar Time Close" "Status Message Message Message";
    font-size: .8em;
    background-color: white;
    margin-left: 5px;
    padding: 4px;
    margin-bottom: 5px;
    border: 1px solid #eee;
}

.chat-box-pres {
    /*background-color: #EBF3F4;*/
    background-color: white;
    margin: 0px 0px 00px 00px;
    padding: 5px 5px 5px 0px;
    border: 1px solid #eee;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
    height: calc(40vh - 50px);
}

.h-100 .chat-box-pres {
    height: calc(80vh - 50px);
}

.chat-box-presenter {
    background-color: #EBF3F4;
    margin: 0px 10px 10px 10px;
    padding: 5px 5px 5px 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
}


.chat-box-search {
    background-color: #EBF3F4;
    margin: 0px 0px 00px 00px;
    padding: 5px 5px 5px 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
    height: calc(70vh - 50px);
    overflow-y: scroll;
    overflow-x: hidden;
}

/*.chat-box-am {
    background-color: #EBF3F4;
    padding: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 10px
}*/
.chat-box-am {
    background-color: #ffffff;
    padding: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
}
.Pchat-box {
    background-color: #EBF3F4;
    padding: 10px;
    margin: 10px;
    border-radius: 4px;
    min-height: 500px;
    overflow-y: scroll;
}


.chat-heading {
    padding: 5px 10px 5px 0px;
    margin: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #009ca6;
    font-size: 1rem;
    border-bottom: 3px solid #009ca6;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}
.chat-heading-pres {
    padding: 5px 10px 5px 10px;
    margin: 0 10px 0 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: linear-gradient(220deg, #009ca6, #009ca6);
    color: #ffffff;
}
.chat-heading-closed {
    padding: 5px 10px 5px 10px;
    margin: 0 0 0 5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #e9ecef;
    color: #000;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
}
.chat-heading-published {
    padding: 5px 10px 5px 10px;
    margin: 0 0 0 5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #e9ecef;
    color: #000;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
}

.chat-heading-open {
    padding: 5px 10px 5px 10px;
    margin: 10px 0 0 5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #e9ecef;
    color: #000;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
}

.chat-heading-am {
    padding: 10px 10px 10px 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #009ca6;
    font-size: 1rem;
    border-bottom: 3px solid #009ca6;
}

.chat-left-inbox {
    overflow-y: auto;
    height: 72vh;
}

.chat-right-inbox {
    background-color: #fff;
    overflow-y: auto;
    height: 37vh;
    
}

.h-100 .chat-right-inbox {
    height: 77vh;
}

.fullheight {
    height: 100%;
    min-height: 75px;
}

.chat-tiles {
    background-color: #fff;
    height: fit-content;
    margin: 10px;
    overflow-X: hidden;
    vertical-align: middle;
    min-height: 100px;
    border-radius: 8px;
    border: 2px solid #f4f4f4;
}

.Pchat-tiles {
    background-color: #fff;
    height: fit-content;
    margin: 10px;
    /*overflow-X: hidden;*/ /* breaks autocomplete list */
    vertical-align: middle;
    border-radius: 8px;
    border: 2px solid #f4f4f4;
    padding: 10px;
}



.initialsBlock {
    display: inline;
    font-size: 1.5rem;
    padding: 5px 5px 5px 5px;
    border-radius: 5px;
}

.n1 {
    background-color: rgba(255, 99, 132, 0.5);
    color: #ffffff;
}

.n2 {
    background-color: rgba(54, 162, 235, 0.5);
    color: #ffffff;
}

.n3 {
    background-color: rgba(255, 206, 86, 0.5);
    color: #ffffff;
}

.n4 {
    background-color: rgba(75, 192, 192, 0.5);
    color: #ffffff;
}

.n5 {
    background-color: rgba(153, 102, 255, 0.5);
    color: #ffffff;
}

.n6 {
    background-color: rgba(255, 159, 64, 0.5);
    color: #ffffff;
}

.n7 {
    background-color: rgba(255, 99, 132, 1);
    color: #ffffff;
}

.n8 {
    background-color: rgba(54, 162, 235, 1);
    color: #ffffff;
}

.n9 {
    background-color: rgba(255, 206, 86, 1);
    color: #ffffff;
}


.col-left {
    flex: 0 0 14%;
    max-width: 14%;
}

.col-right {
    flex: 0 0 84%;
    max-width: 84%;
}

.chat-tiles-number {
    display: inline-flex;
    align-items: center;
}

.chat-tiles-sort-name {
    display: inline-flex;
    background-color: #DC2B66;
    color: #fff;
    border-radius: 18px;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    position: absolute;
}

.chat-tiles-message {
    margin-left: 15px;
}

.chat-tiles-time {
    float: right;
}

.chat-tiles-innerMessage {
    font-size: 0.8rem;
    margin-top: 15px;
    background: #FAFAFA;
    padding: 10px;
    border-radius: 2px;
}


.btn-virtual-meeting {
    background-color: #009CA6;
    color: white;
    border: 1px #009CA6 solid;
    margin-right: 5px;
    font-size: 0.8rem;
    outline: none;
    border-radius: 25px;
}

.btn-virtual-meeting:hover {
    background-color: #f4f4f4;
    color: #093E52;
    border: 1px solid #e0e0e0;
    margin-right: 5px;
    font-size: 0.8rem;
}

.messageTitle {
    padding: 10px 10px 0 10px;
}

.custom-model-style {
    /*width: 1200px;*/
    height: 2000px;
    max-width: 750px;
}

.messaging-modal-style {
    /*width: 1200px;*/
    /*height: 650px;*/
    max-width: 750px;
    overflow: hidden;
    opacity: .99;
    color: dimgrey;
    border: 1px white;
    border-radius: 5px;
    border-style:groove;
}

.messaging-modal-header {
    background: #fff;
    color: #009CA6;
}

.messaging-modal-body {
    background-color: #ffffff;
    border: 1px;
    opacity: 1;
    padding-top: 0px;
    padding-bottom: 5px;
}

.messaging-modal-footer {
    background: #fff;
    border: 1px;
    opacity: 1;
    padding: 0px;
}

.search input {
    display: inline-block;
    width: 70%;
    padding-right: 10px;
}

.card {
    border: none;
    /*margin-bottom: 15px;*/
    border-radius: 0;
    background-color: transparent;
}

.card-header {
    background-color: #fff;
    border-radius: 0;
    font-size: 14px;
    font-weight: 600;
    color: $primaryColor;
    cursor: pointer;
}

.card-body {
    background: #fff;
    border-radius: 0;
    border-top: 1px solid #f4f4f4;
    padding: 0;
}

.search {
    padding: 10px 0;
    background: #ffffff;
    border-radius: 4px;
    margin: 0 10px;
}

.background-white {
    background-color: #ffffff;
    width: 90%;
}

.background-blue {
    background-color: #22a6b3;
    color: #ffffff;
    width: 90%;
    float: right;
}

.background-blue .chat-tiles-innerMessage {
    background-color: #ffffff;
    color: #093E52
}

.modal-content {
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
    -o-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    -ms-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
}
    
.modal-content .chat-tiles {
    padding: 10px;
}

.modal-content .chat-tiles-innerMessage {
    margin-bottom: 10px;
}

.modal-content .chat-box {
    margin-bottom: 0;
    
}

.modal-content .Pchat-tiles {
    border-radius: 0;
    border: none;
    padding: 0;
}

.modal-body {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
}
   
.error-message {
    width: 100%;
    color: #cc0000;
}

.message-sent {
    width: 100%;
    color: #6B8E23;
}

.header {
    background-color: #fff;
    border-radius: 0;
    font-size: 14px;
    font-weight: 600;
    color: $primaryColor;
}


/*=====================================
Inbox Message Style
=======================================*/
.inbox-message ul {
    padding: 0;
    margin: 0;
}
li .inbox-message{
    padding: 2px;
}

.inbox-message {
    width: 80%;
    display: flex;
}

.inbox-message ul li {
    list-style: none;
    position: relative;
    padding: 0px 0px;
    border-bottom: 2px solid #e8eef1;
}

        .chat-box .inbox-message ul li:hover, .inbox-message ul li:focus {
            opacity: 0.6;
            cursor: pointer;
        }

.inbox-message .message-avatar {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
}

.message-avatar img {
    display: inline-block;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: #f8f8f8;
}

.inbox-message .message-body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    margin-left: 5px;
    font-size: 15px;
    color: #42346F;
    background: white;
    line-height: 1.5;
    font-weight: 400;
    padding: 5px 8px;
    border-radius: 3px;
    overflow-wrap:break-word;
}

.message-body p {
    margin-bottom: 2px;
}

.message-body-heading h5 {
    font-weight: 600;
    font-size: 16px;
    display: inline-block;
    color: #335599;
    margin: 0 0 7px 0;
    padding: 0;
}


.message-body h5 span {
    display: inline-flex;
    border-radius: 5px;
    line-height: 14px;
    font-size: 14px;
    font-style: normal;
    padding: 4px 10px;
    margin-left: 5px;
    margin-top: 0px;
}

    .message-body h5 span.submitName {
        width: 150px;
        margin-left: 0px;
        padding-left: 0px;
    }

    /*
    .message-body h5 span.prioritise {
        background: #a8d7e0;
    }

        .message-body h5 span.prioritise:hover {
            background: #6146d4;
            color: #ffffff;
            cursor: pointer;
        }

    .message-body h5 span.priority {
        background: #6146d4;
        color: #ffffff;
    }

    .message-body h5 span.ignore {
        background: #7b57cb;
        color: #ffffff;
    }

        .message-body h5 span.ignore:hover {
            cursor: pointer;
            background: #946ee9;
        }


    .message-body h5 span.answered {
        background: #009ca6;
        float: right;
        color: #ffffff;
    }

        .message-body h5 span.answered:hover {
            cursor: pointer;
            background: #0cb6c1;
        }
        */

    .message-body h5 span.voter {
        background: #07b107;
    }

    .message-body h5 span.guest {
        background: #dd2027;
    }

    .message-body h5 span.nonvotingmember {
        background: #2196f3;
    }

    .message-body h5 span.messageID {
        color: #bbbbbb;
    }

    

.message-body-heading span {
    float: right;
    color: #62748F;
    font-size: 14px;
}


.messages-inbox .message-body p {
    margin: 0;
    padding: 0;
    line-height: 27px;
    font-size: 16px;
}

a:hover {
    text-decoration: none;
}
.carousel-control-next-icon {
    background-image: none;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    right: 2px;
}
    .carousel-control-next-icon:before {
        content: "\f054";
        color: #000;
    }
.carousel-control-prev-icon {
    background-image: none;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    left: 2px;
}

    .carousel-control-prev-icon:before {
        content: "\f053";
        color: #000;
    }

.carousel-indicators li {
    background-color: #000;
}

/* new grid template for messages*/

.grid-container {

    font-size: .8em;
    background-color: white;
    margin-left: 5px;
    padding: 4px;
    margin-bottom: 5px;
    border: 1px solid #eee;
    /*max-height: 110px;*/
}

    .grid-container:hover {
        cursor: pointer;
        box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
        -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
        -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
        -o-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
        -ms-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    }
.Status {

    border-right: 1px solid lightgrey;
    text-align: center;
    vertical-align: middle;
}

.Close {
    display: block;

    color: #222222;
    text-align: center;
    /*border: 1px solid lightgrey;*/
    max-height: 1.4rem;
    height: 20px;
    padding-top: 0;
    line-height: 1;
}

    .Close:hover, .Close:focus {
        background-color: #aac;
        color: white;
    }

.Title-Bar {
    font-family: 'Arial Rounded MT',Arial, Helvetica, sans-serif;  
    font-size: 1em;
}

    .inbox1 {
        color: #111111;
    }

    .inbox2 {
        color: orange;
    }

    .inbox4 {
        color: #7b57cb;
    }

    .inbox8 {
        color: #3ea913;
    }

.mread {
    color: grey;
}

.munread {
    color: red;
}


.ignoreButtonHolder {
    float: right;
    white-space:nowrap;
}

.ignore {
    background: #7b57cb;
    padding: 0 10px 1px 10px;
    /*border-radius: 1px;*/
    color: #ffffff;
    font-size: .8em;
    margin: 2px;
}

    .ignore:hover {
        cursor: pointer;
        background: #946ee9;
        /*box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);*/
    }

.answered {
    background: #009ca6;
    padding: 0 10px 1px 10px;
    /*border-radius: 1px;*/
    font-size: .8em;
    color: #ffffff;
    margin: 2px;
}

    .answered:hover {
        cursor: pointer;
        background: #0cb6c1;
        /*box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);*/
    }

.recovermsg {
    display: inline-flex;
    background: #009ca6;
    padding: 0 5px 0px 5px;
    border-radius: 1px;
    color: #ffffff;
    font-size: .8em;
    white-space: nowrap;
    margin-top:-2px;
}

    .recovermsg:hover {
        cursor: pointer;
        background: #009ca6;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    }

.MessageForward {
    grid-area: 2 / 2 / 3 / 5;
    padding: 5px;
    color: dimgrey;
}

.Message {
    grid-area: 2 / 2 / 3 / 5;
    padding: 5px;
    color: dimgrey;
    overflow: hidden;
    max-height: 45px;
    min-height: 45px;
}

.MessageFull {
    grid-area: 2 / 2 / 3 / 5;
    padding: 5px;
    color: dimgrey;
    overflow: hidden;

}

.Time {
    grid-area: 1 / 3 / 2 / 4;
    white-space:nowrap;
}

.ModeratorMessage {
    grid-area: 3 / 2 / 4 / 5;
    color: grey;
    font-style: italic;
}

.ChatBack {
    height: 500px;
    background-color: lightgrey;
    opacity: .8;
    padding: 10px;
    border: 1px grey;
    margin: 10px;
}


.msg-container-attendee {
    display: grid;
    grid-template-columns: .2fr 1fr .3fr;
    grid-gap: 0px 10px;
    grid-template-areas: "Datetime Message Options" ". Response .";
    margin-bottom: 15px;
    
}

    .msg-container-attendee .DateTime {
        grid-area: Datetime;
        text-align: center;
        font-size: .8rem;
        color: darkgrey;
        margin: auto;
        font-weight: 300;
    }

    .msg-container-attendee .MessageFull {
        grid-area: Message;
        border-radius: 5px;
        border: 1px solid #f0f0f0;
        background-color: #EBF3F4;
        color: dimgrey;
        padding: 5px;
        /*        box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
        -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
        -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
        -o-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
        -ms-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);*/
    }

    .msg-container-attendee .Options {
        grid-area: Options;
        padding-left: 20px;
        margin: auto;
        font-size: 1em;
    }

    .msg-container-attendee .Response {
        grid-area: Response;
        font-style: italic;
        font-size: .8em;
        background-color: rgba(103, 139, 197, 0.73);
        color: white;
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 15px;
        padding: 5px;
    }

.msg-container-window {
    height: 450px;
    border: 1px solid #eee;
    margin: 0 15px 0 15px;
    padding: 5px 15px 5px 15px;
    background-color: white;
    margin-top: 10px;
    }

.msg-container-admin {
    display: grid;
    grid-template-columns: .4fr 1fr .2fr;
    /*grid-template-rows: 0.3fr 0.5fr;*/
    grid-gap: 15px 0px;
    grid-template-areas: "Options Message Datetime" ". Response .";
    
}

    .msg-container-admin .DateTime {
        grid-area: Datetime;
        text-align: center;
        font-size: .8em;
        color: darkgrey;
    }

    .msg-container-admin .MessageFull {
        grid-area: Message;
        border-radius: 5px;
        border: 1px solid #f0f0f0;
        background-color: rgba(20, 20, 255, 0.1);
        color: darkslateblue;
        padding: 5px;
    }

    .msg-container-admin .Options {
        grid-area: Options;
        font-size: .8em;
        float: right;
        text-align: right;
        padding-right: 10px;
        padding-top: 7px;
    }

    .msg-container-admin .Response {
        grid-area: Response;
        font-style: italic;

    }

.closeBox {
    float: right;
    display: block;
    position: absolute;
    padding: 2px 5px;
/*    border: 1px solid #ffffff;*/
    margin-left: 92%;
    
}


    .closeBox:hover {
        cursor: pointer;
        background: #ffffff;
        color: black;
    }

.closeBoxSendTo {
    float: right;
    display: inline-block;
    position: absolute;
    padding: 2px 5px;
    border: 1px solid #ffffff;
    margin-left: 58%;
}

.closeBoxSendTo:hover {
    cursor: pointer;
    background: #ffffff;
    color: black;
}

.msgTitle {
    float: left;
    display: flex;
    margin-left: 0px;
    padding: 2px 5px;
    border-bottom: 3px solid #009ca6;
    width: 100%;
}
.msgTitlePink {
    float: left;
    display: flex;
    margin-left: 0px;
    padding: 2px 5px;
    border-bottom: 3px solid #D62598;
    width: 100%;
}

.msgforward {
    color: cadetblue;
}

    .msgforward:hover {
        color: hotpink;
    }

.msginbox {
    display: block;
    background-color: cadetblue;
    color: white;
    font-size: 0.9em;
    padding: 4px;
    border-radius: 5px;
}

.unreadcnt {
    font-size: .8rem;
    color: darkred;
    border: 1px solid red;
    padding: 1px 5px 1px 5px;
    margin-left: 10px;
}

.chatInboxIcons > div {
    font-size: .8em;
    color: white;
    padding-left: 2px;
    padding-right: 2px;
}

.InModeratorMailbox {
    background-color: darkorange;
}

.InPresenterMailbox {
    background-color: darkslateblue;
}

.InTechMailbox {
    background-color: yellowgreen;
}

.InRequestMailbox {
    background-color: darkslategrey;
}

.adminNameLabel {
    text-align: center;
    font-size: 0.8rem;

}


.nodots {
    padding: 0px;
}

.dragHandle {
    display: flex;
    text-align: left;
    align-items:center;
    justify-content: center;
    vertical-align: central;
    font-size: 1.5em;
    /*rotation: 90deg; -- Removed as suggested by Sonaqube*/
}

.floatConstraint {
    position: relative
}

.updateMsgButton {
    position: absolute;
    top: 5px;
    right: 50px;
    padding: 0 10px 0 10px;
    border-radius: 5px;
    text-align: right;
    color: white;
    background-color: darkblue;
    overflow: visible;
    z-index: 99;
    font-weight: 500;
}

    .updateMsgButton:hover {
        cursor: pointer;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    }

.input-group {
    width: 100%;
    margin:0px;
    padding: 5px 15px 10px 15px;
}

.send_btn
{
    color: green;
}

.ignoredFlag {
    padding-left: 5px;
    color: red;
    font-size: .8em;
}

.answeredFlag {
    padding-left: 5px;
    color: green;
    font-size: .8em;
}

.report-heading {
    padding: 10px;
    margin-top: 100px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: linear-gradient(220deg, #009ca6, #009ca6);
    color: #ffffff;
}
.Meetig-heading {
    padding: 10px;
    margin-top: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: linear-gradient(220deg, #009ca6, #009ca6);
    color: #ffffff;
}

.groupChat {
    position: fixed;
    bottom: 60px;
    font-size: 2em;
    border-radius: 50%;
    padding: 4px;
    width: 62px;
    height: 60px;
    opacity: .8;
    background-color: #D62598;
    /*border: 1px solid grey;*/
    box-shadow: 5px 5px 5px rgba(0,0,0,.1);
}

.groupChatLeft {
    left: 60px;
}
.groupChatRight {
    right: 60px;
}

    .groupChat button {
        position: relative;
        border-radius: 50%;
        height: 50px;
        width: 52px;
        font-size: 1.5rem;
        background-color: #D62598;
        color: white;
    }

    .groupChatTitle {
        display: flex;
        position: fixed;
        bottom: 30px;
        left: 55px;
        color: white;
        font-size: .9em;
        opacity: 1;
    }

.messageAlertCounter {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #fc5c65;
    color: white;
    border-radius: 50%;
    font-size: 0.7em;
    height: 25px;
    width: 25px;
    text-align: center;
    animation: incoming 1s 1;
}

@keyframes incoming {
    0% {height: 25px;
        width: 25px;
    }
    50% {height: 40px;
        width: 40px;
        font-size: 1em;
        top: -12px;
        right: -12px;
    }
    100% {
        height: 25px;
        width: 25px;
    }
}

.removeChat {
    background-color: white;
    border-radius: 50%;
    color: darkgrey;
    font-size: .8rem;
    padding: 1px 5px 1px 5px;
    margin-left: 5px;
    cursor: pointer;
}

.react-scroll-to-bottom--css-lmvux-1tj0vk3{
    right: 14px !important;
    bottom: 4px !important;
}


    .SeenAndHeard .SeenAndHeard:nth-child(1) {
        border-top: 0;
    }

.SeenAndHeard .row .tableHeading {
    text-align: center;
    padding: 5px;
    border: 1px solid #efefef;
}

.SeenAndHeard .request-name{
    font-size: 0.8em;
}

.SeenAndHeard .request-type {
    font-size: 0.8em;
    border: 1px solid #009CA6;
    border-radius: 3px;
}


.genChat {
    color: #3188ed;
    display: inline-block;
}

.speakLive {
    color: #e65d5d;
    display: inline-block;
}

.pointOrder {
    color: #48ca7f;
    display: inline-block;
}

.techSupport {
    color: #9012a1;
    display: inline-block;
}

.speaklivelink{
    width: 100%;
}

.SeenAndHeard .presenterRequest {
    font-size: 0.8rem;
}
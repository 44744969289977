body {
}

.PermissionsRow :hover
{
    background-color: #d0d0d0
}




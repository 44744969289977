
.slate-editor .code-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0, 0,0.9);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slate-editor .codeToTextWrapper {
    width: 80%;
    height: 80%;
    grid-template-columns: 45% 10% 45%;
}
.slate-editor .codeToText {
    width: 100%;
    height: 90%;
    display: grid;
    grid-template-columns: 45% 10% 45%;
}
    .slate-editor .codeToText textarea, .textOutput {
        border-radius: 15px;
        padding: 10px;
    }

    .slate-editor .codeToText textarea {
        resize: none;
    }
        .slate-editor .codeToText textarea:focus {
            outline: none;
        }
.slate-editor .textOutput {
    background: #fff;
    overflow: scroll;
}
.slate-editor .codeToTextWrapper button {
    margin: 3% 1%;
    padding: 10px 37px;
    cursor: pointer;
    border-radius: 5px;
    opacity: 1;
    font-weight: bolder;
}
.slate-editor .done {
    background: #44c767;
    color: #fff;
}
.slate-editor .clear {
    background: #fff;
    color: #a9a4a4;
    border: 1px solid lightgray;
}